/*
  Build using the Gruntfile.js
  ------------
  Installation
  ------------
  Create Gruntfile.js

  $ cd YOUR_PROJECT_DIRECTORY
  $ npm init
  $ npm install grunt grunt-contrib-less grunt-contrib-watch grunt-postcss pixrem autoprefixer cssnano jit-grunt --save-dev

  $ grunt  
*/


@primeColour: #CE3A88;
@cupid: #FAB8DA;
@graynickel: #C7C7C6;
@tint1:lighten(@primeColour,10%);
@tint2:lighten(@primeColour,20%); 
@tint3:lighten(@primeColour,72%);


html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, address, dfn, em, img,
small, strong,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td{
          margin:0;
         padding:0;
          border:0;
         outline:0;
       font-size:100%;
  vertical-align:baseline;
      background:transparent;
}    

a { 
  margin:0; 
  padding:0;
  font-size:100%; 
  vertical-align:baseline; 
  background:transparent; 
}

table { 
  border-collapse:collapse; 
  border-spacing:0; 
}

hr { 
  display:block; 
  height:1px; 
  border:0; 
  border-top:1px solid #ccc; 
  margin:1em 0; 
  padding:0; 
}

input, select { vertical-align:middle; }
/* END RESET CSS */


.d-block {
	display: block;
}

.d-inline { 
	display: inline;
}

.list-style-none {
	list-style: none;
}

.list-style-reset {
	padding: 0;
	margin: 0;
}

.mb-2 {
	margin-bottom: 2rem;
}

.py-1 {
	padding: 1rem 0;
}

.px-1 {
	padding: 0 1rem;
}


/* fonts */
body { 
  font:13px sans-serif; 
  *font-size:small; 
  *font:x-small; 
  line-height:1.45; 
}

table { 
  font-size:inherit; 
  font:100%; 
}

select, input, textarea { font:99% sans-serif; }

small { font-size:.5em;}


/* minimal base styles */
body, select, input, textarea { color:#444; }

h1,h2,h3,h4,h5,h6 { 
  font-weight: bold; 
  text-rendering: optimizeLegibility; 
}

html { -webkit-font-smoothing: antialiased; }

a:hover, a:active { outline: none; }

a, a:active, a:visited { color:@primeColour; }

a:hover { color:@tint2; }

ul { margin-left:30px; }

ol { margin-left:30px; list-style-type: decimal; }

small { font-size:85%; }

strong, th { font-weight: bold; }

td, td img { vertical-align:top; } 

input[type="radio"] { vertical-align: text-bottom; }

input[type="checkbox"] { 
  vertical-align: bottom; 
  *vertical-align: baseline; 
}

label, input[type=button], input[type=submit], button { cursor: pointer; }
::-moz-selection{ 
  background: @primeColour; 
  color:#fff; 
  text-shadow: none; 
}

::selection { 
  background:@primeColour;
  color:#fff; 
  text-shadow: none; 
} 

a:link { -webkit-tap-highlight-color: #FF5E99; } 

html { overflow-y: scroll; }

button {  
  width: auto; 
  overflow: visible;
}


/* Forms */
fieldset { margin-bottom: 2em; }

legend { 
  font-weight: bold; 
  font-size:1.2em; 
  padding-bottom:.5em; 
}

input[type=text], input[type=password], input.text, input.title, textarea, select { 
  background-color:#fff; 
  border:1px solid #bbb;
}

input[type=text]:focus, input[type=password]:focus, input.text:focus, input.title:focus, textarea:focus, select:focus { border-color:#666; }

input.text, input.title  { 
  width: 300px; 
  padding:4px;
}

select { padding:5px;}

input.title { font-size:1.5em; }

textarea { 
  width: 390px; 
  height: 75px; 
  padding:5px;
}

input[type=checkbox], input[type=radio], input.checkbox, input.radio { 
  position:relative; 
  top:.25em; 
}

fieldset input[type="checkbox"], fieldset input[type="radio"], fieldset input.checkbox, fieldset input.radio { top:-4px; }

form.inline { line-height:3; }

input.short, textarea.short, select.short { width:60px; }

input.med, textarea.med, select.med {width:150px;}

input.long, textarea.long, select.long { width:300px;}

input.dateCal { 
  width:70px; 
  padding-right:20px; 
  background:url(/imgs/icons/calendar-select.png) no-repeat 98% 50% #FFF; 
}

input.tel { width:90px;}

input.postcode { width:70px;}

input.address { margin-bottom:.2em;}

.filter { 
  background:@tint3; 
  padding:10px 16px; 
  font-size:11px; 
  font-weight:bold; 
  margin-bottom: 1em; 
}

.filter input { 
  font-size:11px;  
  margin-left:2px;
}

.filter .text { border-color:@tint2;} 

.filter label, .filter div { 
  float:left; 
  height:40px; 
  margin-right:12px; 
  white-space:nowrap;
}

.filter .action { margin-top:6px; }

label .required { color: #C00; }


/* Success, notice and error boxes */
.error, .notice, .success, .info { 
  padding: .8em; 
  margin-bottom: 1em;
}

.error { 
  background:#C00; 
  color:#FFF;
}

.notice { 
  background: #FFF6BF;
  color: #514721;
}

.success { 
  background: #E6EFC2; 
  color: #264409;
}

.info { 
  background: #D4E6F5; 
  color: #5190C0;
}

.error a, .error a:active, .error a:visited { 
  color: #FFF; 
  text-decoration:underline;
}

.notice a, .notice a:active, .notice a:visited {
  color: #514721;
  text-decoration:underline;
}

.success a, .success a:active, .success a:visited { 
  color: #264409; 
  text-decoration:underline;
}
.info a, .info a:active, .info a:visited { 
  color: #5190C0;
  text-decoration:underline;
}


/* Warning */
#warning { 
  position:absolute; 
  left:50%; 
  z-index:110; 
  width:440px; 
  margin-left:-251px; 
  color:#FFF; 
  padding:10px 10px 6px 52px; 
  background:url(/imgs/icons/32/exclamation.png) #C00 no-repeat 10px 14px;
}

#warning .errTitle { 
  font-weight:bold; 
  font-size:18px; 
  margin-bottom:.5em;
}

#warning p { margin-bottom:.5em;}

#warning a { 
  color:#FFF; 
  text-decoration:underline;
}

/* Awesome Buttons*/
.action, .action:visited {  
  font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;  
  background: #222; 
  display: inline-block; 
  padding: 5px 10px 6px; 
  color: #F5F5F5; 
  text-decoration: none; 
  border-bottom: 1px solid rgba(0,0,0,0.25); 
  position: relative; 
  cursor: pointer; 
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.25);
}

.action:hover { color: #fff; }

.action:active { top: 1px; }

.action, .action:visited, .medium.action, .medium.action:visited { 
  font-size: 12px; 
  font-weight: bold; 
  line-height: 1.1em;
}

.small.action, .small.action:visited { 
  font-size: 10px; 
  padding: 4px 8px 4px 8px;
}

.large.action, .large.action:visited { 
  font-size: 16px; 
  padding: 8px 14px 9px;
}

.positive.action, .positive.action:visited, .modal .buttons input[type=button].positive.action, .modal .buttons input[type=button].positive.action:visited { 
  background-color: @primeColour; 
  border:1px solid @tint2; 
  color:#FFF;
     background-image: -moz-linear-gradient(top, @tint1, @primeColour); 
     background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, @tint1),color-stop(1, @primeColour)); 
               filter:  progid:DXImageTransform.Microsoft.gradient(startColorStr='@tint1', EndColorStr='@primeColour'); 
           -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='@tint1', EndColorStr='@primeColour')";
}

.negative.action, .negative.action:visited { 
  background-color: #dbdbdb; 
  border:1px solid #BBB; 
  color:#666;
     background-image: -moz-linear-gradient(top, #FFFFFF, #dbdbdb); 
     background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #FFFFFF),color-stop(1, #dbdbdb)); 
               filter:  progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#dbdbdb'); 
           -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#dbdbdb')";
}

.action:disabled { 
  background: #dbdbdb; 
  border-color:#dbdbdb; 
  color: #999; 
  cursor: default;
}

.action:disabled:active { top: 0px;  }

button.icon { 
  padding-left:5px; 
  padding-bottom: 3px; 
}

button.icon img { vertical-align: middle; }


/* Tables */
table.zebra tr:nth-child(even) { background-color: @tint3; }

table.list {
  border-collapse:separate; 
  border-spacing: 0; 
  width:auto; 
  line-height: 1.23em; 
  color: #222; 
  margin-bottom:1.2em;
}

table.list th { 
  padding: 6px 10px; 
  white-space: nowrap; 
  color:@primeColour; 
  font-size:.9em; 
  text-align: left;
}
table.list th:first-child, table.list td:first-child { 
  padding-left: 16px; 
  text-align: left;
}

table.list td { 
  padding: 6px 10px;
}

table.list tr:last-child td { border-bottom: 0; }

td.money { 
  text-align:right; 
  white-space: nowrap;
}

td.date, td.number { text-align:center; }

tr td.control { width:100px; }

tr td.control a { display:none; }

tr:hover td.control a { display:inline;}

table.zebra tr { cursor:pointer; }

table.zebra tr:hover td { 
	color:#FFF; 
	background:@cupid;
}

table.read-only tr { cursor: default; }

table.read-only tr:hover td { 
  background: none; 
  color:#222;
}


th.sorted { background-color:@tint3;}

/*IE Only */
table.zebra { border-collapse: collapse; }

.zebra .even td { background:@tint3; }

.zebra .mo {background-color:#F00; }

.zebra .odd  td { background:#FFF; }

table.read-only tr.even:hover td {background:@tint3;}


/* Date Picker */
.datepicker_dashboard { 
  position: absolute; 
  font-size: 10px; 
  color: #444; 
  line-height: normal; 
  width: 182px; 
  height: 150px; 
  padding: 0px; 
  background: #FFF; 
  -moz-box-shadow: 0px 1px 5px rgba(0,0,0,0.5); 
  -webkit-box-shadow: 0px 1px 5px rgba(0,0,0,0.5); 
  box-shadow: 0px 1px 5px rgba(0,0,0,0.5); 
  border:1px solid #aaa; 
}

.datepicker_dashboard .header { 
  height: auto; 
  position: relative;
  margin-bottom: 5px;
  background:#C73D00;
  padding:4px 4px; 
}
.datepicker_dashboard .header .title { 
  text-align: center;
  margin: 2px 18px 0 18px;
}
.datepicker_dashboard .header .titleText { 
  font-size:12px;
  font-weight:bold;
  color:#FFF;
  text-transform:uppercase; 
  }
.datepicker_dashboard .header .previous,
.datepicker_dashboard .header .next,
.datepicker_dashboard .header .closeButton { 
  position: absolute;
  cursor: pointer;
  text-indent: -40px;
  overflow: hidden;
  width: 12px;
  height: 12px;
  top: 6px;
  background-image: url(/imgs/datePicker/buttons.png);
  background-position: left top;
  background-repeat: no-repeat; 
}

.datepicker_dashboard .header .previous {left: 4px;}

.datepicker_dashboard .header .next {
  right: 4px;
  background-position: -13px top;
}

.datepicker_dashboard .body { 
  position: relative;top: 0px;
  left: 8px;width: 168px;
  height: 112px;
  overflow: hidden;
  font-weight:bold;
}

.datepicker_dashboard .time {
  position: relative;
  width: 100%;
  height: 100%;
}
.datepicker_dashboard .time .hour,
.datepicker_dashboard .time .separator,
.datepicker_dashboard .time .minutes {
  background: #333;
  border: 0px;
  width: 50px;
  font-size: 32px;
  color: #fff;
  position: absolute;
  top: 10px;
  text-align: center;
  padding: 2px;
}

.datepicker_dashboard .time .hour {left: 15px;}

.datepicker_dashboard .time .separator {
  background: transparent;
  width: 10px;
  left: 76px;
}

.datepicker_dashboard .time .minutes {left: 95px;}

.datepicker_dashboard .time .ok {
  position: absolute;
  top: 65px;
  height: 32px;
  width: 136px;
  left: 15px;
  font-size: 20px;
}

.datepicker_dashboard .days .day {
  float: left;
  text-align: center;
  overflow: hidden;
  width: 23px;
  padding-top: 1px;
  height: 14px;
  margin: 0 1px 1px 0;
}

.datepicker_dashboard .days .titles {
  height: 15px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #999;
  font-weight:bold;
}

.datepicker_dashboard .days .day0 {margin-right: 0;}

.datepicker_dashboard .days .week5 .day {margin-bottom: 0;}

.datepicker_dashboard .days .week .day {cursor: pointer;}

.datepicker_dashboard .days .week .day:hover {color: #C73D00;}

.datepicker_dashboard .days .otherMonth {color: #AAA;}

.datepicker_dashboard .days .selected {color: #C73D00;}

.datepicker_dashboard .months .month {
  float: left;
  cursor: pointer;
  text-align: center;
  padding-top: 6px;
  width: 55px;
  overflow: hidden;
  height: 21px;
  margin: 0 1px 1px 0;
}

.datepicker_dashboard .months .month3,
.datepicker_dashboard .months .month6,
.datepicker_dashboard .months .month9,
.datepicker_dashboard .months .month12 {margin-right: 0;}
.datepicker_dashboard .months .month10,
.datepicker_dashboard .months .month11,
.datepicker_dashboard .months .month12 {margin-bottom: 0;}

.datepicker_dashboard .months .month:hover {color: #C73D00;}

.datepicker_dashboard .months .selected {color: #C73D00;}

.datepicker_dashboard .years .year {
  float: left;
  cursor: pointer;
  text-align: center;
  padding-top: 6px;
  width: 32px;
  overflow: hidden;
  height: 21px;
  margin: 0 1px 1px 0;
}

.datepicker_dashboard .years .year4,
.datepicker_dashboard .years .year9,
.datepicker_dashboard .years .year14,
.datepicker_dashboard .years .year19 {margin-right: 0;}

.datepicker_dashboard .years .year15,
.datepicker_dashboard .years .year16,
.datepicker_dashboard .years .year17,
.datepicker_dashboard .years .year18,
.datepicker_dashboard .years .year19 {margin-bottom: 0;}

.datepicker_dashboard .years .year:hover {color: #C73D00;}

.datepicker_dashboard .years .selected {color: #C73D00}

.datepicker_dashboard .unavailable {
  color: #533 !important;
  cursor: default !important;
  text-decoration: line-through;
}

.MooDialog {
  position: fixed; 
  margin-top:-230px; 
  width:600px; 
  margin-left: -300px; 
  top: 50%;
  left: 50%;
  z-index: 50000;
  background: #000;
  color: #444;
  -moz-box-shadow: 1px 1px 5px rgba(0,0,0,0.8);
  -webkit-box-shadow: 1px 1px 5px rgba(0,0,0,0.8);
  box-shadow: 1px 1px 5px rgba(0,0,0,0.8); 
}

.MooDialogTitle { padding-top: 30px; }

.MooDialog .content { background:#FFF; }

.MooDialog .title {
  padding: 8px 10px;
  background: @primeColour;
  border-bottom: 1px solid #a1aec5;
  font-weight: bold;
  color: #FFF;
  margin-bottom:4px;
}

.MooDialog .close {
  background: url(/imgs/icons/cross-circle-frame.png) no-repeat;
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  top: -5px;
  right: -5px;
  position: absolute;
}

.MooDialog .buttons {
  text-align: right;
  margin: 0;
  border: 0;
  background: #F2F2F2;
  padding:10px;
  border-top:1px solid #CCC;
}

.MooDialog .buttons input[type=button], .MooDialog .buttons input[type=submit] { 
	background-color: #dbdbdb; 
  border:1px solid #BBB; 
  color:#666;
     background-image: -moz-linear-gradient(top, #FFFFFF, #dbdbdb); 
     background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #FFFFFF),color-stop(1, #dbdbdb)); 
               filter:  progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#dbdbdb'); 
           -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#dbdbdb')";
}

.MooDialog .iframe { 
  width: 100%; 
  height: 100%;
}

.MooDialog .textInput { 
  width: 200px; 
  float: left; 
  margin:0; 
  padding:4px;
}

.MooDialog .MooDialogAlert,
.MooDialog .MooDialogConfirm,
.MooDialog .MooDialogPrompt,
.MooDialog .MooDialogError { 
  padding:10px 10px 0 10px; 
  width: 600px;
}

.dialogBody { padding:10px;}

.modal { display: none; }


/* Tabs */
ul.tabbed{ 
  height:20px; 
  list-style:none; 
  margin:0;
}

ul.tabbed li { 
  float:left; 
  background-color:#D4E6F5; 
  cursor:pointer; 
  padding:4px 15px 4px 15px; 
  position:relative; 
  top:1px; 
  margin-right:2px; 
  color:@primeColour; 
  font-weight:bold;
}

ul.tabbed li.selected { 
  background-color:@tint3;  
  color:#444; 
}

ul.tabbed-contents { 
  height:100px; 
  clear:both; 
  background-color:@tint3; 
  list-style:none; margin:0; 
}

ul.tabbed-contents li { padding:10px; }


/* Form Check */
.fc-tbx .tl{ background: url('/imgs/formCheck/tl.png') no-repeat; }

.fc-tbx .t{ background: url('/imgs/formCheck/t.png') repeat-x; height: 16px;}

.fc-tbx .tr{ background: url('/imgs/formCheck/tr.png') no-repeat; }

.fc-tbx .l{ background: url('/imgs/formCheck/l.png') repeat-y; width : 16px; }

.fc-tbx .r{ background: url('/imgs/formCheck/r.png') repeat-y; width: 16px; }

.fc-tbx .bl{ background: url('/imgs/formCheck/bl.png') no-repeat; }

.fc-tbx .b{ background: url('/imgs/formCheck/b.png') no-repeat; height: 25px; }

.fc-tbx .br{ background: url('/imgs/formCheck/br.png') no-repeat; }

.fc-tbx .c{ background: url('/imgs/formCheck/c.png') repeat; }

.fc-tbx a.close { 
  float: right;
  background: url('/imgs/formCheck/close.png') no-repeat;
  position: relative;
  margin-left: 5px;
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.fc-tbx .err {float: left;}
.fc-tbx p {
  width : auto;
  display: block;
  font-size: 11px; 
  font-weight:bold; 
  margin : 0;
  padding : 0;
  border : 0;
  color : #FFF;
}
.ajax_loader {
  background : url('/imgs/formCheck/ajax-loader.gif') no-repeat center;
  width : 200px;
  height : 150px;
}

.fc-error {
  border : 1px solid #888;
  margin-top : 5px;
  background-color : #EAEAEA;
}
.fc-error p {
  margin : 5px;
  color : #A00;
}


/* Horizontal Menu */
.header .user { 
  position: absolute; 
  top:8px; 
  right:24px; 
  color: #EEE; 
}

.header .user a { text-decoration: underline; }

.header .user img { vertical-align: middle;}

.header .user .accountName { 
  color: #FFF; 
  text-decoration: none;
}

.header .user .accountName:hover { color: #FFF;}

/*
	Primary Styles
    Author: Accent Design Group
*/

body { 
  font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
  background:#f1f1f1 ;
}
body.wide .main { 
  margin-left:0; 
  margin-bottom: 20px;
}

body.break .message { 
  background:url(/imgs/icons/32/exclamation.png) no-repeat 24px 24px #FFF; 
  padding:24px;
}

body.break a, body.break a:active, body.break a:visited { color:@primeColour;}

body.break a:hover { text-decoration:underline; }

body.break h1 { padding-left:40px;} 

.container { 
  background: #FFF;
  padding-bottom:10px; 
}

.h2 { 
  font-size: 16px; 
  color: #000; 
  margin-bottom: 10px; 
}

.footer {
  border-top: 10px solid @primeColour;
  .wrapper {
    width: 1024px;
    margin: 0 auto;
    padding: 20px 0 0 0;
    overflow: hidden;
    .leftcolumn {
      float: left;
      width: 48%;
      padding-top: 25px;
      p.small { margin-bottom: 10px; }
      a {
        color: #5C1C4F;
        font-weight: normal;
      }
    }
    .rightcolumn img { float: right; }
  }
}

.col-l { 
  float: left; 
  width:75%;
}

.col-r { 
  float: left; 
  width:25%;
}

.text-right { 
	text-align: right;
}

.text-center {
	text-align: center;
}


p,dl{ margin:0 0 1.2em; }

p.small { 
  font-size:.90em; 
  margin-bottom:6px;
}

input.small, ul.small, li.small, table.small, a.small, span.small { font-size:.90em; }

.meta { 
  background-color:#999; 
  color:#F5F5F5; 
  padding:5px 10px; 
  float:left;
	  -moz-border-radius: 6px; 
   -webkit-border-radius: 6px; 
		   border-radius: 6px; 
}

.right { 
  float:right; 
  margin-bottom:1em; 
  margin-left:1em;
}
.left { 
  float:left; 
  margin-bottom:1em; 
  margin-right:1em;
}

.last { 
  margin-right:0; 
  padding-right:0;
}

.quiet { color:#999;}

.clear { clear:both;}

em { color:#52616C; font-style:italic; }

a { text-decoration:none; }

h1,h2,h3,h4,h5,h6{font-weight:400;}

h1{
  font-size:2.4em;
  line-height:1;
  margin-bottom:.5em;
}

h2{
  font-size:1.8em;
  margin-bottom:.6em;
}

h3{
  font-size:1.5em;
  line-height:1;
  margin-bottom:0.6em;
}

h4{
  font-size:1.2em;
  line-height:1.25;
  margin-bottom:1.25em
}

h5{
  font-size:1em;
  font-weight:700;
  margin-bottom:1.5em
}

h6{
  font-size:1em;
  font-weight:700
}


.header, .main { 
  position: relative;  
  padding: 24px 24px 0px 24px; 
}

.header { 
  background-color: @primeColour;
  color: #FFF;
  padding-top:16px;
  padding-bottom:16px; 
}

.header .quiet, .header .logout .quiet { color: #CCC; }

.header .large { 
  font-size:1.7em; 
  text-transform: uppercase; 
  line-height: normal;  
}

.main { margin-left:206px; }

form.singleColumnVerticalForm legend { padding-bottom:.8em; font-size: 1.8em; font-weight: normal; }

form.singleColumnVerticalForm fieldset fieldset { 
  padding-left: 200px;
  margin-bottom:1.2em;
}

form.singleColumnVerticalForm fieldset fieldset legend { 
  font-size:1em;
  padding-bottom:.5em;
}

form.singleColumnVerticalForm p { 
  clear: both;
  position: relative; 
  padding-left: 200px; 
} 

form.singleColumnVerticalForm label:first-child { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 190px; 
  font-weight:bold;
}

.contactClaimantDiv{
    padding-top: 20px;
    display:none;
}

.hidden{ display:none; }

/* Filter Table */

.filter.smart {
  background: #FFFFFF;
  padding:0;
  input {
    padding:4px 8px 4px 28px;
    background-color: #FFFFFF;
    background-image: url("/imgs/icons/magnifier.png");
    background-position: 5px 50%;
    background-repeat: no-repeat;
    width:200px;
  }
}


.highlighted{ background: #ffff00; }

/* 
  Non-semantic helper classes 
*/
.clearfix:after { 
  content: "."; 
  display: block; 
  height: 0; 
  clear: both; 
  visibility: hidden; 
}

.clearfix { display: inline-block; }

.clearfix { display: block; }

.m-r-md { margin-right:.5rem; }


.test {
  .container {
    background-color: #FFFFFF;
    background-image: url('../imgs/test-tile.png');
  }
}

/* print styles */
@media print {
  * { 
    background: transparent !important; 
    color: #444 !important; 
    text-shadow: none;
  }
  a, a:visited { color: #444 !important; /*text-decoration: underline;*/ }
  
  img { page-break-inside: avoid; }
  
  @page { margin: 0.5cm; }
  
  p, h2, h3 { 
    orphans: 3;
    widows: 3;
  }
  
  h2, h3{ page-break-after: avoid; }
  
  .navi { display: none; }
  
  table.list th:first-child, table.list td:first-child { padding-left: 0;}
  
  table.list tr { border-bottom: 1px solid #444; }
 
 .main button { display: none; }
 
 .right { margin-left: 0; }
 
 .user { display:none;}
 
}


/* Media queries for responsive design */

@media all and (orientation:portrait) { 
  /* Style adjustments for portrait mode goes here */
}

@media all and (orientation:landscape) { 
  /* Style adjustments for landscape mode goes here */
}


@media screen and (max-width: 640px) {
  /* Prevent iOS, WinMobile from adjusting font size */
  html { 
    -webkit-text-size-adjust:none;
    -ms-text-size-adjust:none;
  }

  form.singleColumnVerticalForm fieldset fieldset { padding-left:0;}
  
  form.singleColumnVerticalForm label:first-child { 
    position: inherit; 
    display: block; 
    font-weight:bold;
  }
  
  form.singleColumnVerticalForm p {padding-left:0;}
}

